import React, { useState, useEffect } from 'react'
import DraggableImage from './components/DraggableImage'
import './App.css'
import artworkData from './assets/artworkData.json'
import Bio from './components/Bio'

const HomePage = () => {
  const modes = ['bio', 'posters', 'illustrations', 'publications']
  const [mode, setMode] = useState('home')
  const [artwork, setArtwork] = useState([]) // Filtered artwork based on mode

  useEffect(() => {
    // Fetch artwork data on initial render
    const fetchData = async () => {
      const response = await fetch(artworkData)
      const data = await response.json()
      setArtwork(data) // Set initial artwork
    }

    if (typeof artworkData === 'string') {
      fetchData()
    } else {
      setArtwork(artworkData)
    }
  }, [])

  const handleModeChange = (newMode) => {
    if (newMode === mode) {
      newMode = 'home'
    }
    setMode(newMode)
  }

  const createNav = () => {
    return (
      <header className='navbar'>
        <nav>
          <ul>
            {modes.map((mode_) => (
              <li
                key={mode_}
                className={`${mode_ === mode ? 'nav-selected' : ''}`}
                onClick={() => handleModeChange(mode_)}>
                <span>{mode_}</span>
              </li>
            ))}
          </ul>
        </nav>
      </header>
    )
  }

  return (
    <div id='home-page'>
      {createNav()}
      {mode === 'bio' && <Bio />}
      <div className='artwork-container'>
        {artwork
          .filter((item) => item.category === mode || mode === 'home')
          .map((image, index) => (
            <DraggableImage key={index} src={image.src} alt={image.alt} description={image.description} />
          ))}
      </div>
    </div>
  )
}

export default HomePage
