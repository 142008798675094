import React from 'react'

const Bio = () => {
  return (
    <div id='text-panel'>
      <p>My biography :)</p>
      <p>
        The issue might be related to how React updates the UI. While the artwork state is updated correctly in
        handleModeChange, React might not always immediately re-render the component to reflect the change. Here are
        some ways to address this:
      </p>
      <p>Lorem ipsum</p>
      <span id='links'>
        <a href='facebook.com'>facebook</a>
        <a href='facebook.com'>instagram</a>
      </span>
    </div>
  )
}

export default Bio
