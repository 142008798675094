import React from 'react'
import './App.css'
import logo from './assets/logo.png'
import HomePage from './HomePage'

const App = () => {
  return (
    <div>
      <HomePage />

      <div className='sticky'>
        <div className='logo'>
          <img src={logo} alt='Karolina Król logo' />
        </div>

        <footer>
          <p>Refresh to rearrange, click image to learn more.</p>
        </footer>
      </div>
    </div>
  )
}

export default App
