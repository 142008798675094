// src/components/Modal.js
import React from 'react'
import './Modal.css'

const Modal = ({ isOpen, onClose, children, description }) => {
  if (!isOpen) return null

  return (
    <div className='modal-overlay' onClick={onClose}>
      <div className='modal-content' onClick={(e) => e.stopPropagation()}>
        <button className='close-button' onClick={onClose}>
          &times;
        </button>
        {children}
        <p>{description}</p>
      </div>
    </div>
  )
}

export default Modal
